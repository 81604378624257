import axios from '../utils/axios';

class AdminService {
  async getOrders() {
    return await axios.get("/admin/orders").then((response) => {
      return response.data;
    });
  }

  async acceptOrder(uuid, server_ip) {
    const data = {server_ip: server_ip};
    
    return await axios.post("/admin/orders/" + uuid + "/accept", data).then((response) => {
      return response.data;
    });
  }

  async rejectOrder(uuid) {
    return await axios.post("/admin/orders/" + uuid + "/reject").then((response) => {
      return response.data;
    });
  }

  /******* TICKETS *********/
  async getTickets() {
    return await axios.get("/admin/tickets").then((response) => {
      return response.data;
    });
  }

  async getTicketInfo(uuid) {
    return await axios.get("/admin/tickets/" + uuid).then((response) => {
      return response.data;
    });
  }

  async sendAnswer(uuid, data) {
    return await axios.post(`/admin/tickets/${uuid}/answer`, data).then((response) => {
      return response.data;
    });
  }
}

export default new AdminService();