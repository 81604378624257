import { MainComponent } from "../main";
import { NavbarComponent } from "../navbar";
import AdminService from "../../../../services/AdminService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getTickets = async () => {
      const [response] = await Promise.all([AdminService.getTickets()]);

      setTickets(response.tickets);
      setLoading(false);
    };

    getTickets();
  }, []);

  const getStatusClassName = (status) => {
    switch(status) {
      case 'init':
        return 'badge bg-gradient-warning';
      case 'answered_by_user':
        return 'badge bg-primary';
      case 'answered_by_admin':
        return 'badge bg-gradient-success';
      case 'referred':
        return 'badge bg-info';
      case 'closed':
        return 'badge bg-dark';
    }
  }

  const showDetails = (ticket_id) => {
    navigate(`/admin/tickets/${ticket_id}`);
  };

  if (loading) return "loading ...";

  return (
    <>
      <NavbarComponent />
      <MainComponent>
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-header pb-0">
                <h6>مدیریت تیکت ها</h6>
              </div>
              <div className="card-body px-0 pt-0 pb-2">
                <div className="table-responsive p-0">
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          کاربر
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          شناسه تیکت
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          تاریخ ایجاد
                        </th>
                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          وضعیت
                        </th>
                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          اولویت
                        </th>
                        <th className="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.map((ticket) => {
                        return (
                          <tr>
                            <td>
                              <div className="d-flex px-2 py-1">
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 text-sm">
                                    {ticket.user}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {ticket.uuid.substring(0, 6)}
                              </p>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {ticket.created_at}
                              </p>
                            </td>
                            <td className="align-middle text-center text-sm">
                              <span className={`badge badge-sm ${getStatusClassName(ticket.status)}`}>
                                {ticket.status_name}
                              </span>
                            </td>
                            <td className="align-middle text-center">
                              <span className="text-secondary text-xs font-weight-bold">
                                {ticket.priority_name}
                              </span>
                            </td>
                            <td className="align-middle">
                              <button className="btn btn-success btn-sm text-white font-weight-bold text-xs" onClick={() => showDetails(ticket.uuid)}>جزئیات</button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainComponent>
    </>
  );
};

export default Tickets;
