import axios from '../utils/axios';
import AuthService from './AuthService'
import  secureLocalStorage  from  "react-secure-storage";

class UserService {
  getServices() {
    return axios.get("/services").then((response) => {
      secureLocalStorage.setItem("services", JSON.stringify(response.data.services));
      return response.data.services;
    });
  }

  async findItemBySlug(object, slug) {
    const data = JSON.parse(secureLocalStorage.getItem(object));
    const found = data.filter((item) => {return item.name == slug});
    return found[0].uuid;
  }

  async getPlans(slug) {
    if (slug !== 'all') {
      const uuid = await this.findItemBySlug('services', slug);
      return await axios.get("/services/" + uuid + "/plans").then((response) => {
        return response.data.plans;
      });
    } else {
      return await axios.get("/plans").then((response) => {
        return response.data.plans;
      });
    }
  }

  async getPlanInfo(uuid) {
    return await axios.get("/plans/" + uuid).then((response) => {
      return response.data;
    });
  }

  async getOrderInfo(uuid) {
    return await axios.get("/orders/" + uuid).then((response) => {
      return response.data;
    });
  }

  async getPeriods(unige_id) {
    return await axios.get("/periods/" + unige_id).then((response) => {
      return response.data;
    });
  }

  async sendRequest(unige_id, data) {
    return await axios.post("/plans/" + unige_id + "/request", data).then((response) => {
      return response.data;
    });
  }

  async registerTrackingCode(unige_id, data) {
    return await axios.post("/orders/" + unige_id + "/register-tracking-code", data).then((response) => {
      return response.data;
    });
  }
}

export default new UserService();