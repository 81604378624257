import { MainComponent } from "../main";
import { NavbarComponent } from "../navbar";
import AdminService from "../../../../services/AdminService";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const Requests = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUUID, setSelectedUUID] = useState(null);
  const [serverIp, setServerIp] = useState(null);
  const [isInvalid, setiIsInvalid] = useState(false);
  const [message, setMessage] = useState('');

  const getOrders = async () => {
    const [response] = await Promise.all([AdminService.getOrders()]);

    setOrders(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getOrders();
  }, []);

  const getStatusClassName = (status) => {
    switch (status) {
      case "pending":
        return "badge bg-warning";
      case "approved":
        return "badge bg-success";
      case "rejected":
        return "badge bg-danger";
      case "corrected":
        return "badge bg-primary";
    }
  };

  const handleAccept = async (uuid, server_ip) => {
    let result = false;
    await AdminService.acceptOrder(uuid, server_ip).then((response) => {
      if (response.status) {
        getOrders();
        result = true;
      }
    });

    return result;
  };

  const handleReject = (uuid) => {
    AdminService.rejectOrder(uuid).then((response) => {
      if (response.status) {
        getOrders();
      }
    });
  };

  const OrderDetials = ({ details }) => {
    return (
      <>
        {typeof details.location != "undefined" ? (
          <span>
            <b>لوکیشن:</b> {details.location}
          </span>
        ) : (
          ""
        )}
        <br />
        <span>
          <b>تعداد یوزر آنلاین:</b> {details.online_users.toLocaleString()}
        </span>
        <br />
        <span>
          <b>تعداد یوزر رجیستر:</b> {details.registered_users.toLocaleString()}
        </span>
        <br />
        <span>
          <b>دوره:</b> {details.period}
        </span>
        <br />
        {typeof details.shop != "undefined" ? (
          <span>
            <b>شاپ:</b> {details.shop}
          </span>
        ) : (
          ""
        )}
        <br />
        {typeof details.bot != "undefined" ? (
          <span>
            <b>بات:</b> {details.bot}
          </span>
        ) : (
          ""
        )}
        <br />
        {typeof details.protocols != "undefined" ? (
          <span>
            <b>پروتکل ها:</b> {details.protocols.join(',')}
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const applyServerIp = () => {
    setiIsInvalid(false);
    setSending(true);

    if (serverIp) {
      handleAccept(selectedUUID, serverIp)
        .then((result) => {
          if (result) {
            setOpenModal(false);
          }
        })
        .catch((error) => {
          setMessage(error.message)
          setiIsInvalid(true);
        })
        .finally(() => setSending(false));
    } else setiIsInvalid(true);
  };

  const openAcceptModal = (request) => {
    setSelectedUUID(request.uuid);
    setServerIp(request.server_ip);
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  if (loading) return "loading ...";

  return (
    <>
      <NavbarComponent />
      <MainComponent>
        <Modal show={openModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>تایید درخواست</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label htmlFor="serverIp">آدرس سرور به همراه پورت</Form.Label>
            <Form.Control
              defaultValue={serverIp}
              type="text"
              placeholder="192.168.1.1:9898"
              onChange={(event) => setServerIp(event.target.value)}
              required
              isInvalid={isInvalid}
            />
            <Form.Control.Feedback type="invalid">
              {message ? message : 'لطفا یک آدرس وارد نمایید.'}
            </Form.Control.Feedback>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              بستن
            </Button>
            <Button variant="primary" onClick={applyServerIp}>
              {sending ? "در حال ارسال..." : "تایید"}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-header pb-0">
                <h6>مدیریت درخواست ها</h6>
              </div>
              <div className="card-body px-0 pt-0 pb-2">
                <div className="table-responsive p-0">
                  <table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          کاربر
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          کد درخواست
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          پلن
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          قیمت(تتر)
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          جزئیات
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                          تاریخ ایجاد
                        </th>
                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          وضعیت
                        </th>
                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          شماره پیگیری پرداخت
                        </th>
                        <th className="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((item) => {
                        return (
                          <tr key={item.uuid}>
                            <td>
                              <div className="d-flex px-2 py-1">
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 text-sm">{item.user}</h6>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.uuid.substring(0, 6)}
                              </p>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                            <td>{<OrderDetials details={item.details} />}</td>
                            <td>
                              <p className="text-xs font-weight-bold mb-0">
                                {item.created_at}
                              </p>
                            </td>
                            <td className="align-middle text-center text-sm">
                              <span
                                className={`badge badge-sm ${getStatusClassName(
                                  item.status
                                )}`}
                              >
                                {item.status_name}
                              </span>
                            </td>
                            <td className="align-middle text-center">
                              <span className="text-secondary text-xs font-weight-bold">
                                {item.tracking_code}
                              </span>
                            </td>
                            <td className="align-middle">
                              {item.tracking_code &&
                              item.status != "approved" ? (
                                <a
                                  className="btn btn-success btn-sm text-white font-weight-bold text-xs"
                                  data-toggle="tooltip"
                                  data-original-title="Edit user"
                                  onClick={() => {
                                    openAcceptModal(item);
                                  }}
                                >
                                  تایید
                                </a>
                              ) : (
                                ""
                              )}
                              {item.tracking_code &&
                              item.status != "rejected" ? (
                                <a
                                  className="mx-1 btn btn-danger btn-sm text-white font-weight-bold text-xs"
                                  data-toggle="tooltip"
                                  data-original-title="Edit user"
                                  onClick={() => {
                                    handleReject(item.uuid);
                                  }}
                                >
                                  رد
                                </a>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainComponent>
    </>
  );
};

export default Requests;
