import { NavbarComponent } from "./navbar";
import { MainComponent } from "./main";

const AdminTemplate = ({children}) => {
  return (
    <>
      {children}
    </>
  );
};

export { AdminTemplate };
