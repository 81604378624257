import { Link } from "react-router-dom";

const NavbarComponent = () => {
    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-end me-3 rotate-caret bg-white" id="sidenav-main">
            <div className="sidenav-header">
            <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute start-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
            <a className="navbar-brand m-0" href="https://demos.creative-tim.com/soft-ui-dashboard/pages/dashboard.html" target="_blank">
                <span className="me-1 font-weight-bold">پنل مدیریت</span>
            </a>
            </div>
            <hr className="horizontal dark mt-0"/>
            <div className="collapse navbar-collapse px-0 w-auto  max-height-vh-100 h-100" id="sidenav-collapse-main">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link " to="/admin/dashboard">
                        <span className="nav-link-text me-1">داشبورد</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link " to="/admin/requests/latest">
                        <span className="nav-link-text me-1">مدیریت درخواست ها</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link " to="/admin/tickets">
                        <span className="nav-link-text me-1">مدیریت تیکت ها</span>
                    </Link>
                </li>
            </ul>
            </div>
        </aside>
    );
}

export default NavbarComponent