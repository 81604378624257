import { useState, useContext } from "react";
import AuthService from "../../../../services/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";

const Login = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    let data = {
      email: email,
      password,
    };

    AuthService.adminLogin(data)
      .then(
        (response) => {
          if (response.status) {
            authContext.setAdminLoggedIn(true);
            authContext.setUser(AuthService.getCurrentUser("admin"));

            navigate("/admin/dashboard");
          }
        },
        (error) => {
          const response = error.response;
          setFail(response.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <section>
      <div className="page-header min-vh-75">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div className="card card-plain mt-8" style={{direction: "ltr"}}>
                <div className="card-header pb-0 text-left bg-transparent">
                  <h3 className="font-weight-bolder text-info text-gradient">
                    Welcome
                  </h3>
                  <p className="mb-0">
                    Enter your username and password
                  </p>
                </div>
                <div className="card-body">
                  <form
                    role="form"
                    onSubmit={(event) => {
                      handleSubmit(event);
                    }}
                  >
                    <label>Username</label>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="email-addon"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <label>Password</label>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        aria-describedby="password-addon"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn bg-gradient-info w-100 mt-4 mb-0"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
