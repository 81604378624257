import React, { useEffect, useState } from "react";
import { MainComponent } from "../main";
import { Loading, SectionTitleComponent } from "../general";
import { useNavigate, useParams } from "react-router-dom";
import { ShowErrors } from "../../../../helpers/extractErrors";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AdminService from "../../../../services/AdminService";
import { NavbarComponent } from "../navbar";

const swl = withReactContent(Swal);

const ViewTicket = () => {
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [fail, setFail] = useState(null);
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const { ticket_id } = useParams();

  const getTicketInfo = async () => {
    const [response] = await Promise.all([
      AdminService.getTicketInfo(ticket_id),
    ]);

    setTicket(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getTicketInfo();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    setWaiting(true);
    setFail(null);

    let data = new FormData();
    data.append("message", message);
    data.append("attachment", attachment);

    AdminService.sendAnswer(ticket_id, data)
      .then(
        (response) => {
          if (response.status) {
            swl.fire({
              title: <strong>پاسخ شما با موفقیت ثبت شد!</strong>,
              icon: "success",
              willClose: () => {
                getTicketInfo();
                setMessage("");
                setAttachment("");
                return false;
              },
            });
          }
        },
        (error) => {
          const response = error.response;
          setFail(response?.data);
        }
      )
      .finally(() => {
        setWaiting(false);
      });
  };

  if (loading) return "loading ...";

  return (
    <>
      <NavbarComponent />
      <MainComponent>
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-header pb-0">
                <h6> جزئیات تیکت شماره {ticket_id.substring(0, 6)}</h6>
              </div>
              <div className="card-body px-3 pt-0 pb-2">
                <div className="row">
                  <div
                    className="col-sm-8"
                    data-aos="fade-left"
                    data-aos-delay="200"
                  >
                    <div className="row mb-4">
                      {ticket.messages.map((item, index) => {
                        return (
                          <div key={index} className="col-12 mb-2">
                            <div className="card">
                              <div className="card-header text-purple">
                                <div className="card-title">
                                  {item.sender}
                                  <span className="float-start">
                                  {typeof item.attachment !== "undefined" ? <a href={item.attachment} target="_blank">فایل ضمیمه</a> : ''}{item.created_at}
                                  </span>
                                </div>
                              </div>
                              <div className="card-body p-3">
                                {item.message}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr />

                    <form
                      method="post"
                      role="form"
                      className="react-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="file"
                            name="attachment"
                            id="attachment"
                            onChange={(e) => setAttachment(e.target.files[0])}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12 form-group">
                          <textarea
                            name="message"
                            className="form-control"
                            id="message"
                            placeholder="پاسخ"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="mt-3">
                        <Loading waiting={waiting} />
                        <ShowErrors fail={fail} />
                      </div>
                      <div className="text-right mt-3">
                        <button className="btn btn-primary" type="submit">
                          ارسال پاسخ
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className="col-sm-4"
                    data-aos="fade-right"
                    data-aos-delay="200"
                  >
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">اطلاعات تیکت</div>
                      </div>
                      <div className="card-body">
                        <p>
                          <b>شناسه:</b> {ticket_id.substring(0, 6)}
                        </p>
                        <p>
                          <b>عنوان:</b> {ticket.info.title}
                        </p>
                        <p>
                          <b>وضعیت:</b>{" "}
                          <span className="badge bg-primary">
                            {ticket.info.status}
                          </span>
                        </p>
                        <p>
                          <b>اولویت:</b> {ticket.info.priority}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainComponent>
    </>
  );
};

export default ViewTicket;
