import { Link } from "react-router-dom";
import { SectionTitleComponent } from "../../general";

const NotFound = () => {
    return (
        <div className="hero-replace mb-5">
            <div className="container">
                <SectionTitleComponent>اوه، مثل اینکه گم شدی!</SectionTitleComponent>
                <Link to="/">صفحه اصلی</Link>
            </div>
        </div>
    );
}

export default NotFound;