import { useContext, useEffect } from "react";
import AuthService from "../../../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";

const Logout = (props) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    AuthService.logout();
    authContext.setAdminLoggedIn(false);
    navigate("/admin/auth/login");
  });

  return "";
};

export default Logout;
