import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../../services/UserService";
import withReactContent from 'sweetalert2-react-content'
import Swal from "sweetalert2";

const swl = withReactContent(Swal)

const Plan = ({name, period_id, period, price, features, uuid, country, protocols}) => {
    const navigate = useNavigate();

    const handleFreePlan = (plan_id, features, period_id) => {
        const data = {
            protocols: features.protocols,
            online_users: features.online_users,
            registered_users: features.registered_users,
            period: period_id,
        };

        //add country to data if plan has country
        if (typeof features.location !== "undefined") {
            data.location = features.location;
        }

        UserService.sendRequest(plan_id, data)
            .then(
                (response) => {
                    if (response?.status == "success") {
                    const {data} = response;

                    swl.fire({
                        title: <strong>سفارش شما با موفقیت ثبت شد!</strong>,
                        icon: 'success',
                        willClose: () => {
                        navigate("/orders", {
                            state: {
                            order_id: data.order_id,
                            },
                        });
                        return false;
                        }
                    });
                    }
                },
                (error) => {
                    const response = error.response;
                    if (response.status == 403) {
                        swl.fire({
                        title: <strong>شما قبلا از پلن رایگان استفاده نموده اید.</strong>,
                        icon: 'error',
                        });
                    }
                }
            );
    }

    return (
        <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box" data-aos="zoom-in" data-aos-delay="400">
                {/* <span className="advanced">پیشرفته</span> */}
                <h3>{name}</h3>
                <h4>
                    {parseFloat(price).toLocaleString()}
                    <span>تومان</span>
                    <span> {period}</span>
                </h4>
                <ul>
                    {
                        country ? <li>لوکیشن : {country}</li> : ""
                    }
                    <li>پروتکل ها : {protocols.join(',')}</li>
                    <li>تعداد یوزر آنلاین : {features.online_users}</li>
                    <li>تعداد یوزر رجیستر : {features.registered_users}</li>
                </ul>
                <div className="btn-wrap">
                    {
                        price != 0.00 ?
                        <Link to={"/orders/" + uuid + "/create"} className="btn-buy">
                            سفارش دهید
                        </Link>:
                        <button onClick={() => {handleFreePlan(uuid, features, period_id)}} className="btn-buy">
                            سفارش دهید
                        </button>
                    }
                    
                </div>
            </div>
        </div>
    );
}

export default Plan;