import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router";
import { AuthContext } from "../context/authContext";
import ConditionalRoute from "../../utils/conditionalRoute";
import { Outlet } from "react-router-dom";

//themes
import { AdminTemplate } from "../layouts/admin";

//use pages
import {
  Logout,
  Login,
  Dashboard,
  NotFound,
  Tickets,
  Requests,
} from "../layouts/admin/pages";
import ViewTicket from "./admin/pages/ViewTicket";

const AdminLayout = () => {
  return (
    <AdminTemplate>
      <Outlet />
    </AdminTemplate>
  );
};

const AppRoutes = () => {
  const authContext = useContext(AuthContext);

  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route path="/" element={<Navigate to="/admin/dashboard" />} exact />

        <Route
          path="/admin/auth/login"
          element={
            <ConditionalRoute
              condition={authContext.adminLoggedIn}
              to="/admin/dashboard"
            >
              <Login />
            </ConditionalRoute>
          }
        />

        <Route
          path="/admin/dashboard"
          element={
            <ConditionalRoute
              condition={!authContext.adminLoggedIn}
              to="/admin/auth/login"
            >
              <Dashboard />
            </ConditionalRoute>
          }
        />

        <Route
          path="/admin/requests/latest"
          element={
            <ConditionalRoute
              condition={!authContext.adminLoggedIn}
              to="/admin/auth/login"
            >
              <Requests />
            </ConditionalRoute>
          }
        />

        <Route
          path="/admin/tickets"
          element={
            <ConditionalRoute
              condition={!authContext.adminLoggedIn}
              to="/admin/auth/login"
            >
              <Tickets />
            </ConditionalRoute>
          }
        />

        <Route
          path="/admin/tickets/:ticket_id"
          element={
            <ConditionalRoute
              condition={!authContext.adminLoggedIn}
              to="/admin/auth/login"
            >
              <ViewTicket />
            </ConditionalRoute>
          }
        />

        <Route path="/admin/logout" element={<Logout />} exact />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { AppRoutes };
